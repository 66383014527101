<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-width-1-1">
      <h1 class="uk-heading-line">
        {{ headerTitle }} 
      </h1>
    </div>
    <edit
      :images="images"
      :loading-data="loadingData"
      :list-address-type="listAddressType"
      :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
      :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
      :set-data-modal-add-edit="setDataModalAddEdit"
    />
    <modal-add-edit-discard 
      :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
      :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
      :handle-modal-button="handleModalEdit"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { notificationSuccess } from '@/utils/notification'
import { PREFIX_IMAGE } from '@/utils/constant'
import Edit from './Edit'
import ModalAddEditDiscard from '@/components/globals/modals/ModalAddEditDiscard'
import getHeaderTitle from '@/utils/header-title'

export default {
  components: {
    Edit,
    ModalAddEditDiscard
  },
  props:{
    id: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      loadingData: true
    }
  },
  computed: {
    ...mapGetters({
      listAddressType: 'masterData/list_address_type',
      getDataModalAddEdit: 'partner/get_data_modal_add_edit',
      getToggleModalAddEditDiscard: 'partner/get_toggle_modal_add_edit_discard'
    }),
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  },
  async mounted() {
    this.loadingData
    await this.actionGetDetailPartner(this.id)
    await this.actionGetDetailAddressPartner({ partner_id: this.id })
    await this.actionGetDetailFacilityPartner({ partner_id: this.id})
    await this.actionGetListAddressType({ data_type: 'address_types' })
    this.loadingData = false
  },
  methods: {
    ...mapActions({
      actionGetDetailPartner: 'partner/getDetailPartner',
      actionGetDetailAddressPartner: 'partner/getListAddressInfoPartner',
      actionGetDetailFacilityPartner: 'partner/getFacilityPartner',
      actionGetListAddressType: 'masterData/getListAddressType',
      updatePartner: 'partner/updatePartner'
    }),
    ...mapMutations({
      setDataModalAddEdit: 'partner/SET_DATA_MODAL_ADD_EDIT',
      setToggleModalAddEditDiscard: 'partner/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    }),
    handleModalEdit() {
      this.setToggleModalAddEditDiscard({
        ...this.getToggleModalAddEditDiscard,
        type: 'save',
        loadingTable: false,
        loadingPopUp: true
      })
      const payload = {
        id: this.id,
        reqBody: {...this.getDataModalAddEdit}
      }
      this.updatePartner(payload).then(result => {
        if (result.message == 'OK') {
          setTimeout(() => {
            this.setToggleModalAddEditDiscard({
              ...this.getToggleModalAddEditDiscard,
              loadingTable: false,
              loadingPopUp: false
            })
            notificationSuccess('Edit Farmer Successful')
          }, 500)
          setTimeout(() => {
            this.$router.push({ name: 'Partner' })
            this.setToggleModalAddEditDiscard({
              targetModals: false,  
              title: '',
              description: '',
              type: '',
              customTitleBtnOk: '',
              customTitleBtnCancel: '',
              customClass: '',
              loadingTable: false,
              loadingPopUp: false
            })
          }, 1000)
        } else {
          this.setToggleModalAddEditDiscard({
            targetModals: false,  
            title: '',
            description: '',
            type: '',
            customTitleBtnOk: '',
            customTitleBtnCancel: '',
            customClass: '',
            loadingTable: false,
            loadingPopUp: false
          })
        }
      })
    }
  }
}
</script>

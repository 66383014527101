<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-top base-card">
      <div class="uk-form-stacked">
        <h4 class="uk-heading-line">
          Farmer Info
        </h4>
        <div
          v-if="loadingData"
          class="uk-flex uk-flex-center uk-flex-middle"
        >
          <div uk-spinner />
        </div>
        <div
          v-else
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Farmer Name
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formPartner.name"
                    v-validate="'required|max:50'"
                    name="partner_name"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Farmer Name"
                    :class="{'uk-form-danger': errors.has('partner_name')}"
                  >
                </div>
                <span
                  v-show="errors.has('partner_name')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('partner_name') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Email
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formPartner.email"
                    v-validate="'required|email'"
                    name="email"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Email"
                    :class="{'uk-form-danger': errors.has('email')}"
                  >
                </div>
                <span
                  v-show="errors.has('email')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('email') }}</span>
              </div>
            </div>
          </div>
          
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Phone Number
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formPartner.phone_number"
                    v-validate="'required|max:16'"
                    name="phone_number"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Phone Number"
                    onkeypress="return /^[0-9+]*$/i.test(event.key)"
                    :class="{'uk-form-danger': errors.has('phone_number')}"
                  >
                </div>
                <span
                  v-show="errors.has('phone_number')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('phone_number') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  NIK
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formPartner.nik"
                    v-validate="'required|max:16'"
                    name="nik"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter NIK"
                    onkeypress="return /^[0-9+]*$/i.test(event.key)"
                    :class="{'uk-form-danger': errors.has('nik')}"
                  >
                </div>
                <span
                  v-show="errors.has('nik')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('nik') }}</span>
              </div>
            </div>
          </div>


          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Status
                </label>
                <div class="uk-form-controls uk-margin-small-top">
                  <label class="switch">
                    <input
                      v-if="formPartner.status == 1"
                      class="switcher"
                      name="status"
                      type="checkbox"
                      checked
                      @change="handleStatus"
                    >
                    <input
                      v-else
                      class="switcher"
                      name="status"
                      type="checkbox"
                      @change="handleStatus"
                    >
                    <span class="slider round" />
                  </label>
                  <span :class="formPartner.status == 1 ? 'label-switcher active ' : 'label-switcher'">{{ formPartner.status == 1 ? status.ACTIVE : status.INACTIVE }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <div
        class="uk-child-width-expand@s uk-grid-medium"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l">
          <h4 class="uk-heading-line">
            Address Info
          </h4>
          <div
            v-if="loadingData"
            class="uk-flex uk-flex-center uk-flex-middle"
          >
            <div uk-spinner />
          </div>
          <div
            v-else
            class="uk-form-stacked form-facility-fields"
          >
            <div
              v-for="(data, index) in address_list"
              :key="index"
              class="uk-child-width-expand@s uk-grid-small" 
              uk-grid
            >
              <div class="uk-width-1-3">
                <div class="uk-form-controls uk-position-relative withlabel">
                  <label class="uk-form-label">
                    Address Type {{ index + 1 }}
                  </label>
                  <select
                    v-model="data.address_type"
                    class="uk-width-expand custom-select"
                    :name="`address_type_${index + 1}`" 
                  >
                    <option
                      value=""
                      hidden
                    >
                      Select Address Type
                    </option>
                    <option
                      v-for="(item, key) in listAddressType"
                      :key="key"
                      :value="item.name"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <img
                    :src="`${images}/icon/ic_chevron_down.svg`"
                    alt="ic_chevron"
                  >
                </div>
              </div>

              <div class="uk-width-1-3">
                <label class="uk-form-label">
                  Address {{ index + 1 }}
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="data.address"
                    :name="`address_${index + 1}`"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Address"
                    :class="{'uk-form-danger': errors.has(`address_${index + 1}`)}"
                  >
                </div>
              </div>

              <div class="uk-width-1-3">
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div>
                    <label class="uk-form-label">
                      Latitude {{ index + 1 }}
                    </label>
                    <div class="uk-form-controls">
                      <input
                        v-model="data.latitude"
                        :name="`latitude_${index + 1}`"
                        class="uk-input"
                        type="text"
                        autocomplete="off"
                        placeholder="Enter Latitude"
                        :class="{'uk-form-danger': errors.has(`latitude_${index + 1}`)}"
                      >
                    </div>
                  </div>

                  <div>
                    <label class="uk-form-label">
                      Longitude {{ index + 1 }}
                    </label>
                    <div class="uk-form-controls">
                      <input
                        v-model="data.longitude"
                        :name="`longitude_${index + 1}`"
                        class="uk-input"
                        type="text"
                        autocomplete="off"
                        placeholder="Enter Longitude"
                        :class="{'uk-form-danger': errors.has(`longitude_${index + 1}`)}"
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="img-fields"
              >
                <img
                  class="ic-delete-form"
                  :src="`${images}/icon/ic_delete_table.svg`"
                  alt=""
                  @click.prevent="handleDeleteFormAddress(index)"
                >
              </div>
            </div>
          </div>
      
          <div
            class="uk-child-width-expand@s uk-grid-small uk-margin-small-top"
            uk-grid
          >
            <button
              class="btn-add-create-form uk-button uk-flex uk-align-center uk-width-expand uk-width-auto@s"
              style="justify-content: flex-start;"
              @click.prevent="handleCreateFormAddress()"
            >
              <img
                :src="`${images}/icon/ic_plus_green.svg`"
                class="uk-margin-small-right cursor-pointer"
              >
              Add Address
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <div
        class="uk-child-width-expand@s uk-grid-medium"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l">
          <h4 class="uk-heading-line">
            Facility
          </h4>
          <div
            v-if="loadingData"
            class="uk-flex uk-flex-center uk-flex-middle"
          >
            <div uk-spinner />
          </div>
          
          <div class="uk-form-stacked form-facility-fields">
            <div
              v-for="(data, index) in facility_list"
              :key="index"
              class="uk-child-width-expand@s uk-grid-small" 
              uk-grid
            >
              <div class="uk-width-1-3">
                <div class="uk-form-controls uk-position-relative withlabel">
                  <label class="uk-form-label">
                    Facility Type {{ index + 1 }}
                  </label>
                  <multiselect 
                    v-model="data.facility_type"
                    label="name"
                    :name="`facility_type_${index + 1}`"
                    track-by="id"
                    placeholder="Select Facility Type or Type to Search"
                    open-direction="bottom"
                    :options="listFacilityType"
                    :searchable="true"
                    :max-height="200"
                    :show-no-results="true"
                    :options-limit="10"
                    @select="() => handleSelectFacilityType(index)"
                    @remove="() =>handleSelectFacilityType(index)"
                  >
                    <span slot="noResult">Oops! Data tidak ditemukan.</span>
                  </multiselect>
                </div>
              </div>

              <div class="uk-width-1-3">
                <div class="uk-form-controls uk-position-relative withlabel">
                  <label class="uk-form-label">
                    Facility Name {{ index + 1 }}
                  </label>
                  <multiselect 
                    v-model="data.facility_name"
                    label="facility_name"
                    :track-by="`vc_code` ? `vc_code` : `vcf_code`"
                    :name="`facility_name_${index + 1}`"
                    placeholder="Select Facility Name or Type to Search"
                    open-direction="bottom"
                    :options="handleOption(data.facility_type?.name)"
                    :searchable="true"
                    :max-height="200"
                    :show-no-results="true"
                    :options-limit="10"
                    :disabled="!data.facility_type"
                    @select="(input) => handleSelectFacilityName(input, index, data.facility_type)"
                    @remove="() =>handleRemove(index)"
                  >
                    <span slot="noResult">Oops! Data tidak ditemukan.</span>
                  </multiselect>
                </div>
              </div>

              <div class="uk-width-1-3">
                <label class="uk-form-label">
                  Export Hub {{ index + 1 }}
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="data.export_hub"
                    :name="`export_hub_${index + 1}`"
                    class="uk-input readonly"
                    type="text"
                    autocomplete="off"
                    readonly
                  >
                </div>
              </div>

              <div
                class="img-fields"
              >
                <img
                  class="ic-delete-form"
                  :src="`${images}/icon/ic_delete_table.svg`"
                  alt=""
                  @click.prevent="handleDeleteFormFacility(index)"
                >
              </div>
            </div>
          </div>
      
          <div
            class="uk-child-width-expand@s uk-grid-small uk-margin-small-top"
            uk-grid
          >
            <button
              class="btn-add-create-form uk-button uk-flex uk-align-center uk-width-expand uk-width-auto@s"
              style="justify-content: flex-start;"
              @click.prevent="handleCreateFormFacility()"
            >
              <img
                :src="`${images}/icon/ic_plus_green.svg`"
                class="uk-margin-small-right cursor-pointer"
              >
              Add Facility
            </button>
          </div>
        </div>

        <div class="uk-flex uk-flex-right">
          <button
            class="uk-button soft-green uk-margin-right"
            @click="showCancelConfirmModal"
          >
            Cancel
          </button>
          <button
            class="uk-button green"
            :class="isCanAccessUser('edit', 'FARMER') ? '' : 'disabled'"
            :disabled="!isCanAccessUser('edit', 'FARMER')"
            @click="showSaveConfirmModal"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { STATUS } from '@/utils/constant'
import { isCanAccess } from '@/utils/auth'

export default {
  props: {
    images: {
      required: true,
      type: String
    },
    loadingData:{
      require: false,
      type: Boolean
    },
    listAddressType: {
      required: true,
      type: Array
    },
    setToggleModalAddEditDiscard: {
      required: true,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    },
    setDataModalAddEdit: {
      required: true,
      type: Function
    }
  },
  data() {
    return{
      status: STATUS,
      formPartner: {},
      facility_type: '',
      address_list: [],
      facility_list: [],
      isUpdateFacilities: false
    }
  },
  computed: {
    ...mapGetters({
      detail_partner: 'partner/detail_partner',
      detail_address_partner: 'partner/list_address_info_partner',
      detail_facility_partner: 'partner/list_facility_partner',
      listFacilityType: 'masterData/list_master_data',
      list_hulling: 'hullingFacility/list_hulling',
      list_pulping: 'pulpingFacility/list_pulping',
      list_plantation: 'plantation/list_plantation'
    })
  },
  watch:{
    async detail_partner(val) {
      if(val) {
        this.formPartner = {
          name: val.name || '',
          phone_number: val.phone_number || '',
          email: val.email || '',
          nik: val.nik || '',
          status: val.status || null,
          addresses: this.address_list,
          facilities: this.facility_list
        }
      }
    },
    async detail_address_partner(){
      this.address_list = this.detail_address_partner?.data
    },
    async detail_facility_partner(){
      const assignListId = (facility_type, loopItem) => {
        if(facility_type == 'Plantation'){
          return {facility_name: loopItem.facility_name, vcf_code: loopItem.facility_id}
        }
        return {facility_name: loopItem.facility_name, vc_code: loopItem.facility_id}
      }
      if(this.detail_facility_partner?.data == null) {
        this.facility_list = [
          {
            facility_id: '',
            facility_name: '',
            facility_type: '',
            export_hub: ''
          }
        ]
      } else {
        this.detail_facility_partner.data.forEach(item => {
          this.facility_list.push({
            facility_id: item.id,
            facility_name: assignListId(item.facility_type, item),
            facility_type: this.listFacilityType.filter(item1 => item1.name == item.facility_type)[0],
            export_hub: item.export_hub_name
          })
        })
      }
    }
  },
  async mounted() {
    await this.actionGetListMasterData({ data_type: 'facility_types' })
    await this.actionGetListHulling({ limit: 1000, is_only_partner_not_exist: true, is_all: true, partner_id: this.$route.params.id })
    await this.actionGetListPulping({ limit: 1000, is_only_partner_not_exist: true, is_all: true, partner_id: this.$route.params.id })
    await this.actionGetListPlantation({ limit: 1000, is_only_partner_not_exist: true, is_all: true, partner_id: this.$route.params.id })
  },
  methods: {
    ...mapActions({
      actionGetListMasterData: 'masterData/getListMasterData',
      actionGetListHulling: 'hullingFacility/getListHulling',
      actionGetListPulping: 'pulpingFacility/getListPulping',
      actionGetListPlantation: 'plantation/getListPlantation'
    }),
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    handleOption(name){
      if(name){
        switch (name) {
        case 'Hulling Facility':
          return this.list_hulling
        case 'Pulping Facility':
          return this.list_pulping
        case 'Plantation':
          return this.list_plantation
        default:
          break
        }
      }
      return []
    },
    handleChange(){
      this.isUpdateFacilities = true
    },
    async handleSelectFacilityType(index){
      this.facility_list[index].facility_name = null
      this.facility_list[index].export_hub = null
      this.handleRemove(index)
      this.handleChange()
    },
    handleSelectFacilityName(input, index, data){
      let idx = input.id
      this.facility_list[index].facility_id = idx
      this.facility_list[index].export_hub = this.handleOption(data.name).filter(item=>item.id == idx)[0].export_hub?.name || '-'
      this.handleChange()
    },
    handleRemove(index){
      this.facility_list[index].export_hub=''
    },
    handleCreateFormFacility() {
      this.facility_list.push({
        facility_id: '',
        facility_type_id: '',
        facility_type: '',
        export_hub: ''
      })
    },
    handleDeleteFormFacility(index) {
      this.handleChange()
      this.$delete(this.facility_list, index)
    },
    handleCreateFormAddress() {
      this.address_list.push({
        address_type: '',
        address: '',
        latitude: '',
        longitude: ''
      })
    },
    handleDeleteFormAddress(index) {
      this.$delete(this.address_list, index)
    },
    handleStatus(event) {
      this.formPartner.status = event.target.checked ? 1 : 0
    },
    showCancelConfirmModal() {
      this.setToggleModalAddEditDiscard({
        targetModals: true,
        title: 'Cancel Confirmation', 
        description: 'All data will be lost if you leave. Are you sure want to leave?',
        customTitleBtnOk: 'Yes',
        customTitleBtnCancel: 'No',
        type: 'discard',
        route: 'Partner',
        loadingTable: false,
        loadingPopUp: false
      })
    },
    showSaveConfirmModal() {
      let tempAddress = []
      let tempFacility = []
      this.address_list.map(item => tempAddress.push({
        id: item.id,
        address_type: item.address_type,
        address: item.address,
        latitude: item.latitude,
        longitude: item.longitude
      }))
      
      this.facility_list.map(item => tempFacility.push({
        facility_id: item.facility_id,
        facility_type: item.facility_type.name
      }))

      this.formPartner.is_update_facilities = this.isUpdateFacilities

      this.formPartner.addresses = tempAddress
      this.formPartner.facilities = tempFacility

      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.setDataModalAddEdit(this.formPartner)
          this.setToggleModalAddEditDiscard({
            targetModals: true,
            title: 'Save Confirmation', 
            description: 'Are you sure want to save this data?',
            customTitleBtnOk: 'Save',
            customTitleBtnCancel: 'Cancel',
            type: 'save',
            loadingTable: false,
            loadingPopUp: false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  .custom-select.disabled{
    background: #DEDEDE;
  }
  .form-facility-fields .uk-width-1-3 {
    max-width: 32%;
  }
  .btn-add-create-form {
    font-family: 'interMedium';
    font-weight: 600;
    font-size: 16px;
    background: transparent;
    color: #2FAC4A;
  }
  .img-fields {
    display: flex;
    justify-items: center;
    align-items: center;
    margin-top: 25px;
    cursor: pointer;
  }
  .ic-delete-form{
    width: 24px;
    height: 24px;
    max-width: 24px !important;
  }
  .uk-input:disabled{
    background: #F4F4F4 !important;
    color: #000000 !important;
  }
  .uk-form-icon {
    width: 24px;
    height: 24px;
    top: 25%;
    z-index: 1;
  }
  .uk-form-icon-flip {
    right: 10px;
    left: auto;
  }
</style>
